<template>
	<div class="contact">
		<div class="header">
			<img src="@/assets/images/location_header.png" alt="">
			<div class="inner animated fadeIn">
				<p>{{$t('lang.home.nav7')}}</p>
				<p>{{$t('lang.contact.header1')}}</p>
			</div>
		</div>
		<div class="address">
			<p class="title"><img src="@/assets/images/location_icon.png" alt="">{{$t('lang.contact.address1')}}</p>
			<p class="add-text">{{$t('lang.contact.address2')}}</p>
			<p v-for="(item,index) of $t('lang.contact.address3')" :key="index">{{item}}</p>
		</div>
		<img src="@/assets/images/map.jpg" alt="" class="map">
	</div>
</template>

<script>
export default {
  name: "contact"
};
</script>

<style lang="scss" scoped>
.contact {
  .address {
    width: 1280px;
    margin: 0 auto;
    p {
      text-align: left;
      font-size: 18px;
      opacity: 0.8;
      line-height: 30px;
      font-family: "OpenSansLight";
      font-weight: 300;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 30px;
      margin: 50px auto;
      opacity: 1;
      font-family: "OpenSansRegular";
      img {
        width: 26px;
        margin-right: 7px;
      }
    }
    .add-text {
      font-size: 24px;
      opacity: 1;
      margin-bottom: 20px;
      font-family: "OpenSansRegular";
      font-weight: 400;
    }
  }
  .map {
    width: 1280px;
    margin: 74px auto;
  }
}
</style>

